import { Component, ViewChild, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app/app.component';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import DateUtil from '../../util/DateUtil.js';

@Component({
  selector: 'roreport-page',
  templateUrl: './ROReportPage.component.html',
  styleUrls: ['./ROReportPage.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ROReportPageComponent implements OnInit {

  snackBar
  showDrafts = false;
  allROsArray = []
  isQuerying = false;
  searchHidden = false;
  RODataSource = new MatTableDataSource(this.allROsArray);
  subscriptionContainer = new Subscription();

  selectedCustomer = "";
  selectedPublication = "";
  selectedDateRangeOption = "currYear";
  startDate = new Date();
  endDate = new Date();

  displayROList = true;
  displayInvoiceList = false;
  
  customersArr = []
  publicationsArr = []
  ROAddressees = new Set(); // Publications (To the Manager)
  ROClients = new Set(); // Clients
  invoiceClients = new Set(); // Clients
  publicationsSet = new Set();
  
  allROColumnDefinitions = ['rowNum','billNo','billDate','insertionDate','clientName','publicationsStr','releaseType','caption','dimensions','isInvoiceGenerated','grossAmount','netAmount','gstAmount','amountPaid']
    

  reportObj = {
    documentType : "",
    client : "",
    publication : "",
    dateRangeStr : "",
    recordCount : "",
    grossAmount : 0,
    netAmount : 0,
    gstAmount : 0,
    amountPaid : 0
  }
  
  @ViewChild('ROListTable', {static: false}) ROListTableElement: MatTable<any>;

  sortM : MatSort
  @ViewChild(MatSort, {static: false}) set sortM_function(content: MatSort) {
    this.sortM = content;
    if(content){
      this.RODataSource.sort = this.sortM;
    }
        
  }

  constructor(private route: ActivatedRoute, private dbAccessor: DBAccessor, private _snackBar: MatSnackBar,
                 private appComponent: AppComponent, private titleService: Title) { 
    titleService.setTitle("Reports");
  }

  ngOnInit() {
    this.subscriptionContainer.add(this.route.data.subscribe((data) => {
      this.showDrafts = data.showDrafts;
      //this.loadContents();
      this.loadAddresseesClients();
      this.selectedPublication = "HT/MINT";
    }));
  }

  fillROTable(ROResults){
      
    let promiseChain = Promise.resolve();
    this.appComponent.setScreenBusyState(true);

    promiseChain = promiseChain.then(() => {
      let res = ROResults;
      let displayDocsArr = [];
      if(res.success){
        res.docs.forEach((resDoc)=>{
          let displayDoc : any = {
            id : resDoc._id,
            rev : resDoc._rev,
            billNo : resDoc.header.billNo,
            billDate : resDoc.header.billDate,
            itemDateRangeStr : "",
            clientName : resDoc.header.client.name,
            publicationsStr : "",
            releaseType : "",
            caption : "-",
            dimensions : "-",
            isInvoiceGenerated : resDoc.header.isInvoiceGenerated,
            grossAmount : resDoc.totalObj && resDoc.totalObj.grossAmount,
            netAmount : resDoc.totalObj && resDoc.totalObj.subtotal.value,
            gstAmount : resDoc.totalObj && resDoc.totalObj.totalGST.value,
            amountPaid : resDoc.totalObj && resDoc.totalObj.netAmount || 0
          };
          let insertionDates = new Set();
          let publicationSet = new Set();
          
          // Get Insertions total count
          let totalInsertionCount = 0;
          let isTextDate = false;
          resDoc.items.forEach((resDocItem) => {
              let newItemDate : Date;
              if(resDoc.header.releaseType == "print" || resDoc.header.releaseType == "printClassified" || resDoc.header.releaseType == "printFreeform"){
                if((!resDoc.columnHeaderDetails || !resDoc.columnHeaderDetails['insertionDate'])
                       || !resDoc.columnHeaderDetails['insertionDate'].isCustom)
                  newItemDate = new Date(resDocItem.insertionDate);
                else{
                  isTextDate = true;
                  newItemDate = null;
                }
                // Setting caption text
                if(resDocItem.caption){
                  if(displayDoc.caption == '-')
                    displayDoc.caption = resDocItem.caption;
                  else
                    displayDoc.caption += " (+)";
                }
                // Setting dimension text
                if(resDocItem.width && resDocItem.height){
                  if(displayDoc.dimensions == '-')
                    displayDoc.dimensions = resDocItem.width + " x " + resDocItem.height;
                  else
                    displayDoc.dimensions += " (+)";
                }
              }
              else if(resDoc.header.releaseType == "radio" || resDoc.header.releaseType == "others"){
                if(!isTextDate){
                  if(resDocItem.campaignDate instanceof Date && !isNaN(resDocItem.campaignDate))
                    newItemDate = new Date(resDocItem.campaignDate);  
                  else{
                    isTextDate = true;
                    newItemDate = null;
                  }
                }
                else
                  newItemDate = null;
              }
              else newItemDate = null;
              if(newItemDate)
                insertionDates.add(newItemDate);
              publicationSet.add(resDocItem.publication);
              totalInsertionCount += resDocItem.insertionCount;
          });

          // Get insertion date range
          if(!isTextDate){
            let maxItemDate = new Date(Math.max.apply(null,Array.from(insertionDates)));
            let minItemDate = new Date(Math.min.apply(null,Array.from(insertionDates)));
            let itemDateRangeStr;
            if(maxItemDate.getTime() == minItemDate.getTime())
              itemDateRangeStr = DateUtil.getDateText(minItemDate);
            else
              itemDateRangeStr = DateUtil.getDateText(minItemDate) + ' - ' + DateUtil.getDateText(maxItemDate);
            displayDoc.itemDateRangeStr = itemDateRangeStr;
          }
          else
            displayDoc.itemDateRangeStr = resDoc.items[0].campaignDateStr;

          // Release Type
          let outReleaseType: string;
          if(resDoc.header.releaseType == "print")
            displayDoc.releaseType = "Print";
          else if(resDoc.header.releaseType == "printClassified")
              displayDoc.releaseType = "Print (Classified)";
          else if(resDoc.header.releaseType == "printFreeform")
              displayDoc.releaseType = "Print (Freeform)";
          else if(resDoc.header.releaseType == "radio")
            displayDoc.releaseType = "Radio";
          else if(resDoc.header.releaseType == "others")
            displayDoc.releaseType = "Others";

          // Put total insertion count
          displayDoc.insertionCount = totalInsertionCount;

          // Put publication list
          let publicationsStr;
          let publicationsArr = Array.from(publicationSet);
          if(publicationSet.size == 1)
            publicationsStr = publicationsArr[0];
          else
            publicationsStr = publicationsArr[0] + '(+ ' + (publicationsArr.length - 1) + ' others)';
          displayDoc.publicationsStr = publicationsStr;
          displayDocsArr.push(displayDoc);
        });
      }
      this.allROsArray = displayDocsArr;
      this.RODataSource.data = this.allROsArray;
      this.RODataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'billNo':
            let finYearStr, finYear, serialNumStr, serialNum;
            let formatType = "";
            if(item[property].split('/')[0] == "ES"){ // New convention
              finYearStr = item[property].split('/')[1] || "20-21";
              finYear = parseInt(finYearStr.split('-')[0]);
              let serialNumStrArr = item[property].split('/');
              serialNum = parseInt(serialNumStrArr[serialNumStrArr.length - 1]);
              formatType = "new";
            }
            else{
              finYearStr = item[property].split('/')[1] || "20-21";
              finYear = parseInt(finYearStr.split('-')[0]);
              serialNumStr = item[property].split('/')[0] || "ES1";
              serialNum = parseInt(serialNumStr.split('ES')[1]);
              formatType = "old";
            }
            let priority = finYear * 2000 + serialNum;
            if(formatType == "new")
              priority += 2000;
            return priority;
          default: return item[property];
        }
      };
      //this.RODataSource.sort = this.sortM;
    });
    
    promiseChain = promiseChain.then(() => {
      this.appComponent.setScreenBusyState(false);
    });

    return promiseChain;
  }

  loadAddresseesClients(){
    return this.dbAccessor.retrieveAllByType("RO", ["header","items"]).then((res) => {
      if(res.success){
        res.docs.forEach((resDoc)=>{
          this.ROAddressees.add(resDoc.header.addressee);
          this.ROClients.add(resDoc.header.client.name);
          resDoc.items.forEach((item) => {
            this.publicationsSet.add(item.publication);
          });
        });
        this.publicationsArr = Array.from(this.publicationsSet); 
        
        this.customersArr = Array.from(this.ROClients);
      }
    });
  }

  onClientListItemClick(selectedCustomerEntry){
    if(selectedCustomerEntry)
      this.selectedCustomer = selectedCustomerEntry;
    else
      this.selectedCustomer = "- No filter -";
  }

  onPublicationListItemClick(selectedPublicationEntry){
    if(selectedPublicationEntry)
      this.selectedPublication = selectedPublicationEntry;
    else
      this.selectedPublication = "- No filter -";
  }

  executeQuery(){
    let queryParams = {
      findParam : {}
    };
    this.isQuerying = true;
    queryParams.findParam["type"] = {
      $eq : "RO"
    };
    
    if(this.selectedCustomer && this.selectedCustomer.length > 0 && this.selectedCustomer != "- No filter -"){
      queryParams.findParam["header.client.name"] = {
          $eq : this.selectedCustomer
      } 
    }
    //let publicationsRegex = /(HT)*(MINT)*(Hindustan)*/g;
    let publicationsRegex = /(Hindustan)+/g;
    queryParams.findParam["items"] = {
      $elemMatch: {
        $or : [
        {
          publication : {
          $regex : /(Hindustan)+/g
          }
        },
        {
          publication : {
            $regex : /(MINT)+/g
            }
        },
        {
          publication : {
            $regex : /(Mint)+/g
            }
        },
        {
          publication : {
            $regex : /(HT)+/g
            }
        }
      ]
        
      }
      
      
    }
    

    // Get date range
    let dateLower, dateHigher;
    if(this.selectedDateRangeOption == "currYear" || this.selectedDateRangeOption == "lastYear" ){
      dateHigher = new Date();
      let lowerYear;
      if(dateHigher.getMonth() >= 3) // if current month is april or later
        lowerYear = dateHigher.getFullYear(); // starting year is current year
      else
        lowerYear = dateHigher.getFullYear() - 1; // starting year is last year

      if(this.selectedDateRangeOption == "lastYear"){
        lowerYear -= 1;
        dateHigher = new Date(lowerYear + 1, 2, 31);
      }
      dateLower = new Date(lowerYear, 3, 1);  // starting date is 1st april
    }
    else if(this.selectedDateRangeOption == "currMonth"){
      dateHigher = new Date();
      dateLower = new Date(dateHigher.getFullYear(), dateHigher.getMonth(), 1);
    }
    else if(this.selectedDateRangeOption == "dateRange"){
      dateLower = this.startDate;
      dateHigher = this.endDate;
    }

    if(this.selectedDateRangeOption != "none"){
      queryParams.findParam["header.billDate"] = {
        $gte : dateLower,
        $lte : dateHigher
      }
    }

    

    return this.dbAccessor.retrieve(queryParams).then((resultSet) => {
      this.fillReportsBlock(resultSet, this.selectedCustomer, this.selectedPublication, [dateLower, dateHigher]);
      this.isQuerying = false;
      return this.fillROTable(resultSet);
      
    }).catch((ex) => {
      this.isQuerying = false;
    });
  }

  fillReportsBlock(resultSet, client, publication, dateRangeArr) {
    this.reportObj = {
      documentType : "",
      client : "",
      publication : "",
      dateRangeStr : "",
      recordCount : "",
      grossAmount : 0,
      netAmount : 0,
      gstAmount : 0,
      amountPaid : 0
    }

    //this.reportObj.documentType = docType;
    this.reportObj.client = client || "";
    this.reportObj.publication = publication || "";
    this.reportObj.recordCount = resultSet.docs.length;
    if(dateRangeArr[0] == null || dateRangeArr[1] == null){
      this.reportObj.dateRangeStr = "- No filter -";
    }
    else{
      this.reportObj.dateRangeStr += DateUtil.getFormattedDate(dateRangeArr[0]) + ' - ' + DateUtil.getFormattedDate(dateRangeArr[1]);
    }

    resultSet.docs.forEach((doc) => {
      //if(docType == "RO"){
        //this.reportObj.client = doc.header.client.name;
      //}
      //else if(docType == "Invoice"){
        //this.reportObj.client = doc.addressee.name
      //}
      this.reportObj.grossAmount += doc.totalObj.grossAmount;
      this.reportObj.netAmount += doc.totalObj.subtotal.value;
      this.reportObj.gstAmount += doc.totalObj.totalGST.value;
      this.reportObj.amountPaid += doc.totalObj.netAmount;
    });
  }

  applySearch(value){

  }

  onRefreshClick() {

  }
  
  remove_linebreaks( str ) {
    return str.replace( /[\r\n]+/gm, "" );
  }

  saveCSV(){
    let csvContent = "data:text/csv;charset=utf-8,";
    let delimiter = ",";
    let headerContentArray = ["Row No.","Bill No.","Bill Date","Insertion Date","Client Name","Publication","Release Type","Caption","Dimensions","Invoice Generated?","Gross Amount","Net Amount","GST Amount","Amount Paid"];
    let headerContent = headerContentArray.join(delimiter);
    csvContent += headerContent + "\r\n";
    let indx = 1;
    this.allROsArray.forEach(RORow => {
      let rowContent = "";
      rowContent += indx++ + delimiter;
      rowContent += RORow.billNo + delimiter;
      rowContent += DateUtil.getDateText(RORow.billDate) + delimiter;
      rowContent += RORow.itemDateRangeStr + delimiter;
      rowContent += '"' + this.remove_linebreaks(RORow.clientName) + '"' + delimiter;
      rowContent += '"' +  this.remove_linebreaks(RORow.publicationsStr) + '"' + delimiter;
      rowContent += RORow.releaseType + delimiter;
      rowContent += this.remove_linebreaks(RORow.caption) + delimiter;
      rowContent += RORow.dimensions + delimiter;
      rowContent += (RORow.isInvoiceGenerated || "FALSE") + delimiter;
      rowContent += RORow.grossAmount + delimiter;
      rowContent += RORow.netAmount + delimiter;
      rowContent += RORow.gstAmount + delimiter;
      rowContent += RORow.amountPaid;
      csvContent += rowContent + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "my_data.csv");
    document.body.appendChild(link);
    
    link.click(); 
  }

}
