import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { PrintService } from '../printService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import userConfig from '../../assets/userConfig.json';
import { PrintItemTableComponent } from '../../forms/ItemTables/Print/PrintItemTable.component';
import { RadioItemTableComponent } from '../../forms/ItemTables/Radio/RadioItemTable.component';
import { PrintItem } from '../../ItemTypes/PrintItem';
import { RadioItem } from '../../ItemTypes/RadioItem';
import { NewInvoiceService } from '../../forms/Invoice/NewInvoiceService.service';
import { PrintClassifiedItemTableComponent } from '../../forms/ItemTables/PrintClassified/PrintClassifiedItemTable.component';
import { PrintClassifiedItem } from '../../ItemTypes/PrintClassifiedItem';
import { PrintFreeformItem } from '../../ItemTypes/PrintFreeformItem';
import { PrintFreeformItemTableComponent } from '../../forms/ItemTables/PrintFreeform/PrintFreeformItemTable.component';
import { OthersItemTableComponent } from '../../forms/ItemTables/Others/OthersItemTable.component';
import { OthersItem } from '../../ItemTypes/OthersItem';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'InvoicePrintPage',
  templateUrl: './InvoicePrintPage.component.html',
  styleUrls: ['./InvoicePrintPage.component.css']
})
export class InvoicePrintPageComponent implements OnInit, AfterViewInit {

  subscriptionContainer = new Subscription();

  addresseeList = []
  //@ViewChild('printItemsTableContainer', {static: false}) printItemsTableContainer: PrintItemTableComponent;
  //@ViewChild('radioItemsTableContainer', {static: false}) radioItemsTableContainer: RadioItemTableComponent;
  
  printItemsTableContainer: PrintItemTableComponent;
  printClassifiedItemsTableContainer: PrintClassifiedItemTableComponent;
  printFreeformItemsTableContainer: PrintFreeformItemTableComponent;
  radioItemsTableContainer: RadioItemTableComponent;
  othersItemsTableContainer: OthersItemTableComponent;

  isProforma = false;
  titleText = "TAX INVOICE";
  routerParentPath = "/Invoice";

  headerObj = {
    addressee: {
      name: "",
      GSTIN: ""
    },
    billNo : "",
    billDate : new Date(),
    releaseType : "",
    GSTIN : "",
    HSNCode : "",
    refRO: {
      refRONum: "",
      refRODate: new Date()
    },
    showRefRO: true,
    hasNewLetterhead : false,
    client : {
      name: ""
    },
    hasRemarks : false,
    remarks : ""
  }
  totalObj = {
    grossAmount : 0,
    additionalComponents : [],
    subtotal : {
      description : "Subtotal",
      value : 0
    },
    CGST : {
      description : "CGST",
      value : 0
    },
    SGST : {
      description : "SGST",
      value : 0
    },
    totalGST : {
      description : "GST",
      value : 0
    },
    netAmount : 0,
    amountInWords : "-"
  }

  

  splitGst = true
  dataToLoad
  invoice_ID = null
  invoice_rev = null
  HSNCode = ""
  
  constructor(private route: ActivatedRoute, private _snackBar: MatSnackBar, private dbAccessor: DBAccessor,
    private cdRef: ChangeDetectorRef, private router: Router, private printService: PrintService,
    private newInvoiceService: NewInvoiceService, private titleService: Title) {
    //this.ROItems = []
    // this.ROItems.push(new PrintItem())
    //this.releaseType = "print";
    //this.headerObj.releaseType = "print";
    this.headerObj.GSTIN = userConfig.GSTIN;
  }

  ngOnInit() {
    /*
    this.subscriptionContainer.add(this.route.data.subscribe((data) => {
      this.createMode = data.createMode;
      this.editMode = data.editMode;
    }));*/
    this.subscriptionContainer.add(this.route.data.subscribe((data) => {
      this.isProforma = data.isProforma;
      if(this.isProforma){
        this.titleText = "PROFORMA INVOICE";
        this.routerParentPath = "/Proforma";
      }
      else{
        this.titleText = "TAX INVOICE";
        this.routerParentPath = "/Invoice";
      }
    }));
  }

  ngAfterViewInit() {
    this.subscriptionContainer.add(this.route.paramMap.subscribe((params) => {
      this.invoice_ID = params.get('invoice_ID');
      if (!this.invoice_ID) {
        return this;
      }

      this.dbAccessor.getByID(this.invoice_ID).then((invoiceData) => {
        this.dataToLoad = invoiceData;
        this.invoice_rev = invoiceData._rev;
        this.setInvoiceHeaderData(invoiceData);
        this.setInvoiceTotalData(invoiceData);
        let titleText = "Invoice_" + invoiceData.header.billNo;
        if(invoiceData.header.addressee.shortCode)
          titleText += ' ' + invoiceData.header.addressee.shortCode;
        let billDateObj = new Date(invoiceData.header.billDate);
        titleText += ' ' + billDateObj.toLocaleDateString('default', {day: "numeric",month: "short"})
        let firstPublication = "";
        if(invoiceData.items && invoiceData.items.length > 0){
          firstPublication = invoiceData.items[0].publicationShortCode || "";
        }
        if(firstPublication && firstPublication.length > 0)
          titleText += ' ' + firstPublication;
        this.titleService.setTitle(titleText);
        //this.setItemData(ROData);
        //this.dataToLoad = null;
      }).catch((err) => {
        console.error("Document not found");
        console.log("Additional error details: " + err);
      })
    }));
  }

  ngOnDestroy() {
    this.subscriptionContainer.unsubscribe();
  }

  @ViewChild('printItemsTableContainer', {static: false}) set printTablecontent(content: PrintItemTableComponent) {
    this.printItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "print"){
        this.setPrintItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printClassifiedItemsTableContainer', {static: false}) set printClassifiedTablecontent(content: PrintClassifiedItemTableComponent) {
    this.printClassifiedItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printClassified"){
        this.setPrintClassifiedItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printFreeformItemsTableContainer', {static: false}) set printFreeformTablecontent(content: PrintFreeformItemTableComponent) {
    this.printFreeformItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printFreeform"){
        this.setPrintFreeformItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('radioItemsTableContainer', {static: false}) set radioTablecontent(content: RadioItemTableComponent) {
    this.radioItemsTableContainer = content;
    if(content){
      this.HSNCode = "998364";
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "radio"){
        this.setRadioItemsData(this.dataToLoad);
        //this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('othersItemsTableContainer', {static: false}) set othersTablecontent(content: OthersItemTableComponent) {
    this.othersItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      this.HSNCode = this.headerObj.HSNCode;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "others"){
        this.setOthersItemsData(this.dataToLoad);
        //this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  setInvoiceHeaderData(inData){
    if(!inData)
      return;
    this.headerObj = inData.header;
  }

  setInvoiceTotalData(inData){
    if(!inData)
      return;
    this.totalObj = inData.totalObj;
  }

  getItemsArray(){
    let itemsArr = [];
    if(this.headerObj.releaseType == "print"){
      itemsArr = this.printItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printClassified"){
      itemsArr = this.printClassifiedItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      itemsArr = this.printFreeformItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "radio"){
      itemsArr = this.radioItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "others"){
      itemsArr = this.othersItemsTableContainer.getItemsArray()
    }
    return itemsArr;
  }

  setItemData(inData){
    if(inData.header.releaseType == "print"){
      this.setPrintItemsData(inData);
    }
    else if(inData.header.releaseType == "printClassified"){
      this.setPrintClassifiedItemsData(inData);
    }
    else if(inData.header.releaseType == "printFreeform"){
      this.setPrintFreeformItemsData(inData);
    }
    else if(inData.header.releaseType == "radio"){
      this.setRadioItemsData(inData);
    }
    else if(inData.header.releaseType == "others"){
      this.setOthersItemsData(inData);
    }
  }

  setPrintItemsData(inData){
    if(!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintItem(inItem);
      this.printItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintClassifiedItemsData(inData){
    if(!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printClassifiedItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printClassifiedItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintClassifiedItem(inItem);
      this.printClassifiedItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintFreeformItemsData(inData){
    if(!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printFreeformItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printFreeformItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintFreeformItem(inItem);
      this.printFreeformItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setRadioItemsData(inData){
    if(!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.radioItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.radioItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new RadioItem(inItem);
      this.radioItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setOthersItemsData(inData){
    if(!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.othersItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.othersItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new OthersItem(inItem);
      this.othersItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  onAddresseeListItemClick(addressee){
    this.headerObj.addressee = addressee;
  }

  printInvoice(){
    window.print();
  }

}
