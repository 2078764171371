import { Component } from '@angular/core';
import { DBAccessor } from '../db/DBAccessor'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'billgen-frontend';
  isScreenBusy = false;

  constructor(private dbAccessor : DBAccessor){
    this.dbAccessor.checkAndInitConfig();
  }
  setScreenBusyState(flag){
    this.isScreenBusy = flag;
  }
}
