import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ROFormComponent } from '../forms/RO/ROForm.component'
import { InvoiceFormComponent } from '../forms/Invoice/InvoiceForm.component';

import { ROListPageComponent } from '../listPages/RO/ROListPage.component';
import { BackupDBComponent } from '../db/BackupDBComponent/BackupDBPage.component';
import { PrintLayoutComponent } from '../printPages/printLayout/printLayout.component';
import { ROPrintPageComponent } from '../printPages/RO/ROPrintPage.component';
import { InvoiceListPageComponent } from '../listPages/Invoice/InvoiceListPage.component';
import { InvoicePrintPageComponent } from '../printPages/Invoice/InvoicePrintPage.component';
import { ConfigPageComponent } from '../db/ConfigPageComponent/ConfigPage.component';
import { GSTListPageComponent } from '../listPages/GST/GSTListPage.component';
import { ProformaListPageComponent } from '../listPages/Proforma/ProformaListPage.component';
import { MonthReportPageComponent } from '../reportPages/MonthReportPage/MonthReportPage.component';
import { CustomerReportPageComponent } from '../reportPages/CustomerReportPage/CustomerReportPage.component';
import { ROReportPageComponent } from '../reportPages/ROReportPage/ROReportPage.component';


const routes: Routes = [
  {
    path: 'RO',
    component: ROListPageComponent,
    data: { createMode: true}
  },
  {
    path: 'RO/create',
    component: ROFormComponent,
    data: { createMode: true, editMode: true}
  },
  {
    path: 'RO/copy',
    component: ROFormComponent,
    data: { createMode: false, editMode: true, fromRO: true}
  },
  {
    path: 'RO/:id',
    component: ROFormComponent,
    data: { createMode: false, editMode: false},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'Invoice',
    component: InvoiceListPageComponent,
    data: { createMode: true, editMode: true, fromRO: false, showDrafts: false}
  },
  {
    path: 'Invoice-drafts',
    component: InvoiceListPageComponent,
    data: { createMode: true, editMode: true, fromRO: false, showDrafts: true}
  },
  {
    path: 'Invoice/create',
    component: InvoiceFormComponent,
    data: { createMode: true, editMode: true, fromRO: false}
  },
  {
    path: 'Invoice/build',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: true, fromRO: true}
  },
  {
    path: 'Invoice/copy',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: true, fromInvoice: true}
  },
  {
    path: 'Invoice/:id',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: false, fromRO: false}
  },
  {
    path: 'Proforma',
    component: ProformaListPageComponent,
    data: { createMode: true, editMode: true, fromRO: false, isProforma: true, showDrafts: false}
  },
  {
    path: 'Proforma-drafts',
    component: ProformaListPageComponent,
    data: { createMode: true, editMode: true, fromRO: false, isProforma: true, showDrafts: true}
  },
  {
    path: 'Proforma/create',
    component: InvoiceFormComponent,
    data: { createMode: true, editMode: true, fromRO: false, isProforma: true}
  },
  {
    path: 'Proforma/build',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: true, fromRO: true, isProforma: true}
  },
  {
    path: 'Proforma/copy',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: true, fromInvoice: true, isProforma: true}
  },
  {
    path: 'Proforma/:id',
    component: InvoiceFormComponent,
    data: { createMode: false, editMode: false, fromRO: false, isProforma: true}
  },
  {
    path: 'GST',
    component: GSTListPageComponent
  },
  {
    path: 'Backup',
    component: BackupDBComponent,
  },
  {
    path: 'Config',
    component: ConfigPageComponent
  },
  {
    path: 'performBackup',
    redirectTo: '/performBackup.php'
  },
  {
    path: 'print'
    //component: PrintLayoutComponent,
    //outlet: 'print-outlet'
    ,
    children: [
      {path: 'RO/:RO_ID', component: ROPrintPageComponent},
      {path: 'Invoice/:invoice_ID', component: InvoicePrintPageComponent},
      {path: 'Proforma/:invoice_ID', component: InvoicePrintPageComponent, data:{isProforma: true}},
      {path: '', component: PrintLayoutComponent}
      //{path: 'Invoice/:invoice_ID', component: }
    ]
  },
  {
    path: 'reports',
    children: [
      {path: 'ByMonth', component: MonthReportPageComponent},
      {path: 'ByCustomer', component: CustomerReportPageComponent},
      {path: 'ROReport', component: ROReportPageComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
